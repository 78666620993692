import React, { useEffect } from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./upotreba.scss"

const Upotreba = ({ data }) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
  ]
  useEffect(() => {
    const links = gsap.utils.toArray(".box")
    gsap.set(links, { opacity: 0, y: 150 })
    links.forEach((link, i) => {
      const anim = gsap.to(link, {
        duration: 1,
        opacity: 1,
        y: 0,
        paused: true,
      })
      ScrollTrigger.create({
        trigger: link,
        end: "bottom bottom",
        once: true,
        onEnter: self => {
          self.progress === 1 ? anim.progress(1) : anim.play()
        },
      })
    })
    const images = gsap.utils.toArray("section:not(:first-of-type) img")
    gsap.set(images, { opacity: 0, scale: 1.5 })
    images.forEach((image, i) => {
      const animImages = gsap.to(image, {
        duration: 1,
        opacity: 1,
        scale: 1,
        paused: true,
      })
      ScrollTrigger.create({
        trigger: image,
        end: "bottom bottom",
        once: true,
        onEnter: self => {
          self.progress === 1 ? animImages.progress(1) : animImages.play()
        },
      })
    })
    document.querySelectorAll(".card a").forEach((a, index) => {
      a.addEventListener("click", e => {
        e.preventDefault()
        gsap.to(window, {
          scrollTo: { y: "#box" + (index + 1) },
          duration: 1.5,
          ease: "power4",
        })
      })
    })
  }, [])

  return (
    <>
      <SEO
        title="Upotreba"
        description="Bahove cvijetne kapi pokrivaju mnoštvo crta licnosti i raspoloženja – neraspoloženja. Rescue Remedy je najpoznatiji Bahov preparat. U njegov sastav ulazi 5 od 38 pojedinačnih cvjetova. Rescue je namijenjen za situacije akutnog stresa i šoka za krizne situacije koje su u toku ili neposredno poslije krize."
      />
      <Layout>
        <article id="upotreba" className="scroll-container">
          <header>
            <h1>
              Bahove cvijetne kapi pokrivaju mnoštvo crta licnosti i
              raspoloženja – neraspoloženja
            </h1>
            <Img fluid={sources} />
          </header>
          <section>
            <div className="card">
              <Img fluid={data.sectionImage2_sm.childImageSharp.fluid} />
              <p>
                <a href="#box1">Upotreba kapi</a>
              </p>
            </div>
            <div className="card">
              <Img fluid={data.sectionImage1_sm.childImageSharp.fluid} />
              <p>
                <a href="#box2">Cvijetno zadovoljstvo</a>
              </p>
            </div>
            <div className="card">
              <Img fluid={data.sectionImage3_sm.childImageSharp.fluid} />
              <p>
                <a href="#box3">Kome i kako pomažu</a>
              </p>
            </div>
            <div className="card">
              <Img fluid={data.sectionImage4_sm.childImageSharp.fluid} />
              <p>
                <a href="#box4">Kako do Bahovih kapi</a>
              </p>
            </div>
            <div className="card">
              <Img fluid={data.sectionImage5_sm.childImageSharp.fluid} />
              <p>
                <a href="#box5">Zašto je bitno obaviti intervju</a>
              </p>
            </div>
          </section>
          <section id="box1">
            <header className="box">
              <h3>Upotreba kapi i njihove karakteristike</h3>
            </header>
            <Img
              fluid={data.sectionImage2_sm.childImageSharp.fluid}
              style={{ maxHeight: "60vh" }}
            />
            <p>
              Bahove cvijetne kapi su u upotrebi širom svijeta još od tridesetih
              godina prošlog vijeka, što će reći oko osamdeset godina i kroz
              iskustvo Bahovih praktičara pokazalo se i dokazalo da:
            </p>
            <ul className="upotreba-list">
              <li>
                kapi nemaju kontraindikacija, mogu da ih koriste čak i bebe,
                trudnice, mame dojilje, vrlo stari i bolešću iscrpljeni ljudi
              </li>
              <li>efikasne su za otklanjanje emotivne i fizičke patnje</li>
              <li>
                efikasne su za sticanje karakteristika koje poboljšavaju
                kvalitet naše svakodnevnice (tolerancija, koncentracija)
              </li>
              <li>nema uzgrednih neželjenih dejstava</li>
              <li>
                primjena ovih kapi ne isključuje korištenje lijekova druge vrste
                kapi ne mogu da se predoziraju
              </li>
              <li>
                primjena kapi je nenasilna, jer se nakapa ispod jezika ili
                utrlja na pulsne tačke (bebe, onesvješćeni ljudi), nakapa na
                dlan bebe, nakapa u čašu vode, sok, vruću supu ili kafu...
              </li>
              <li>
                nije potrebno vjerovati u dejstvo ovih kapi da bi one djelovale,
                jer su kapi efikasne i kad se primjene na čovjeka koji je u
                nesvijesti ili u komi, efikasne su kad ih dajemo bebama, korisne
                kad ih primjenimo na kućnim ljubimcima ili cvijeću na saksijama,
                dakle u prilikama kada korisnik nema nikakav stav o tome
              </li>
              <li>
                jednako su efikasne za rješavanje problema i njegovanje kućnih
                ljubimaca i svih životinja, kao i biljaka
              </li>
              <li>
                kapi nisu osjetljive na spoljne uticaje (zračenja, mobilne
                telefone, mirise, svjetla, rentgenske aparate na
                aerodromima,...) pa je njihovo čuvanje vrlo jednostavno
              </li>
              <li>
                kapi mogu da se nose u tašni ili džepu, a u radnom ili stambenom
                prostoru mogu da stoje na bilo kom mjestu. Mogu se držati u
                frižideru u slučajevima kada se ne upotrebljavaju stalno, već
                samo po potrebi (kao što je npr. Rescue Remedy)
              </li>
            </ul>
            <h4>Rescue Remedy</h4>
            <p>
              Ovo je zasigurno najpoznatiji Bahov preparat. U njegov sastav
              ulazi 5 od 38 pojedinačnih cvjetova. Rescue je namijenjen za
              situacije akutnog stresa i šoka za krizne situacije koje su u toku
              ili neposredno poslije krize. On ne preuzima mjesto medicinskom
              tretmanu, ali pomaže da se izbjegne mentalna patnja, omogućava
              tijelu da bez odlaganja započne sopstveni proces iscjeljenja. To
              je preparat za sve hitne situacije – znači kad postoje stanja
              panike, šoka, mentalne utrnulosti. On je umirujući i u
              traumatičnim situacijama kao što su: letenje, posjeta zubaru,
              ispit ili neki važan razgovor, vakcinisanje, primjena neke
              terapije na koju pacijent reagira alergijski, iznenadne neprijatne
              vijesti ili povrede, saobraćajne nesreće, prirodne katastrofe,
              toksične situacije ili iznenadna smrt nekog bliskog ili poznatog,
              itd.
            </p>
            <p>
              Rescue Remedy se može koristiti na razne načine što zavisi od
              potrebe i situacije. Može se nakapati 4 kapi u čašu vode i
              ispijati gutljaj po gutljaj, u što češćim intervalima, a može se
              direktno iz flašice nakapati 4 kapi direktno ispod jezika. U
              situaciji kada je neko u nesvjesnom stanju, nakvase mu se usne
              preparatom ili se isti nakaplje na pulsne tačke na ručnom zglobu
              ili iza uha. Kod spoljnih povreda koje su nastale silovito može da
              se stavi na povrijeđeno mjesto kompresa natopljena rastvorom
              Rescue Remedy iz čaše.
            </p>
            <p>
              Pošto Rescue nije preparat za sve, ako se desi da neko prečesto
              poseže za njim, to onda ukazuje da je osoba u krizi i da joj treba
              kombinacija koja će vremenom otkloniti probleme koji je muče.
              Postoji i Rescue krema koja u sebi pored nabrojanih preparata
              sadrži još i jabuku koja je izvrstan čistač i dezinficijens, a ne
              sadrži lanolin. Krema je idealna za modrice, nagnječenja,
              otekline, ubode insekata, posjekotine i manje opekotine.
            </p>
          </section>
          <section id="box2">
            <header className="box">
              <h3>Cvijetno zadovoljstvo</h3>
            </header>
            <Img
              fluid={data.sectionImage1_sm.childImageSharp.fluid}
              style={{ maxHeight: "70vh" }}
            />
            <p>
              Bahove kapi su namijenjene za rast i razvoj ličnosti i iz toga
              proizilazi da podstiču i fizičko zdravlje.
            </p>
            <p>
              Svi Bahovi preparati, a ima ih 38 + Rescue Remedy, kao preparat
              Prve pomoći, pomažu da STEKNEMO MUDROST i da se sa ličnim i
              životnim preprekama konstruktivno suočimo. Preparati nam pomažu
              kako da PREVAZIĐEMO prepreke ka harmoniji sa samim sobom i sa
              sveukupnim okruženjem. Te prepreke su: strah, posesivnost,
              usamljenost, sebičnost, sumnja, neznanje, pretjerana brižnost,
              nestrpljivost, pesimizam, itd.
            </p>
            <p>
              Dva su osnovna uslova da čovjek diše punim plućima: prvo, da
              spozna sebe sa svim svojim nedostacima, manama, vrlinama,
              nevještinama, itd, i drugo, da stekne visok stepen tolerancije na
              frustracije.
            </p>
            <p>
              Dr. Bah je krenuo od pretpostavke da je svako biće sretno i
              zadovoljno kad je u vezi, u harmoniji, sa sopstvenom prirodom, tj.
              kad je autentičan. Kad je ovaj odnos prekinut, pojavljuje se
              unutrašnja neravnoteža. Bolešljivost ili konkretna bolest je
              posljedica naše emotivne neravnoteže. Prvi signal je naše
              neraspoloženje i nezadovoljstvo. Ako to ne prepoznamo i ništa na
              tome ne radimo – osjećanja se intenziviraju i pojavljuju se prvi
              fizički simptomi. Kao posljedica neravnosteže javlja se bolest.
            </p>
            <p>
              Da bismo se liječili, moramo da se bavimo UZROKOM, a postojanje
              unutrašnje neravnoteže stvara sklonost ka povredama i infekcijama.
              Postojanje bolesti ili povrede povratno djeluju na unutrašnju
              neravnotežu, dodatno je povećavajući.
            </p>
            <p>
              Dr. Bah je zaključio da je posao praktičara da pomaže klijentu da
              osvijesti svoje stanje, da upozna sebe kako bi mogao da otkloni
              prepreke unutar bića. Bahovi preparati nisu lijek za svaku boljku:
              farmakoterapijom se saniraju fizički i tjelesni simptomi, a
              Bahovom terapijom saniraju se prateća negativna, emotivna i
              mentalna stanja, a ujedno čiste od negativnih posljedica
              farmakoterapije i homeopatije.
            </p>
            <p>
              Samim tim što su napravljeni od samoniklih biljaka metodom
              osunčavanja i metodom kuhanja, što su neškodljivi, što ne
              izazivaju nikakve kontraindikacije, primjena je utoliko
              komfornija. Mogu da ih koriste trudnice, bebe, novorođenčad, majke
              dojilje, stari i iznemogli ljudi, biljke i životinje, svi oni koji
              nemaju nikakvih predrasuda o kapima i djelovanjima.
            </p>
            <p>
              S obzirom da je čovjek slojevito biće, i njegovi problemi su
              slojeviti kao glavica luka. Kombinacija preparata djeluje na ono
              što je vidljivo i čega je klijent svjestan. Skidamo sloj po sloj
              dok ne dođemo do uzroka problema, čega klijent nije svjestan ili
              postaje svjestan tokom intervjua sa praktičarem.
            </p>
            <p>
              Stoga, Bahov praktičar ima holistički pristup klijentu, a metoda
              spada u komplementarnu metodu, jer dozvoljava paralelno korištenje
              preparata klasične medicine, alopatskih i homeopatskih preparata.
            </p>
          </section>
          <section id="box3">
            <header className="box">
              <h3>Kome i kako pomažu Bahove kapi</h3>
            </header>

            <Img
              fluid={data.sectionImage3_sm.childImageSharp.fluid}
              style={{ maxHeight: "60vh", opacity: "0.85" }}
            />
            <ul className="upotreba-list">
              <li>
                pomoć trudnicama – prava kombinacija kapi će pomoći trudnicama
                da se lakše fokusiraju na sam porođaj i prevladaju svoju
                osjetljivost i doživljaj istog i samim tim će on biti lakši
              </li>
              <li>
                umiruju djecu – bebe i djeca lijepo reaguju na kapi, pa im one
                olakšavaju trenutke kada su razdražljivi, plačni, bolesni i
                imaju temperaturu, a sam tok bolesti se mijenja i skraćuje
              </li>
              <li>
                nesanica – kapi pomažu, jer osvješćuju umor, a odmor je onda
                dubok i okrepljujući. Važno je naglasiti da se prvo mora otkriti
                uzrok nesanice da bi se mogla napraviti prava kombinacija kapi
              </li>
              <li>
                alergije – koža je ogledalo unutrašnjeg nereda i cvijetne kapi
                smanjuju osip po koži, svrab u očima, iritabilni kašalj, itd
              </li>
              <li>
                oporavak od operacije – sa kapima prije i poslije operacije
                uveliko se smanjuje doživljaj ličnih simptoma koji su posljedica
                anestezije
              </li>
              <li>
                psihosomatske bolesti – sve bolesti psihosomatskog porijekla
                (kao što je i psorijaza) mogu dužom upotrebom kapi da prorijede
                simptome, kao i njihov intenzitet
              </li>
            </ul>
            <h4>
              Bahove cvijetne kapi pokrivaju mnoštvo crta ličnosti i
              raspoloženja – neraspoloženja.
            </h4>
            <p>Ovo su neki primjeri problema (djeca i odrasli):</p>
            <ul className="upotreba-list">
              <li>
                Za one koji skrivaju svoju duboku unutrašnju patnju iza
                nasmijanog lica i uvijek raspoloženog stava
              </li>
              <li>
                Za one koji teško ili nikako ne tolerišu različitost, tuđe mane,
                nesposobnosti, uvjereni da je njihov način - jedini pravi način
              </li>
              <li>
                Za tihe, povučene, nesigurne, plašljive, sebične, posesivne...
              </li>
              <li>Duhom odsutne, sanjalice, impulsivne...</li>
              <li>Hiperaktivne, brzoplete...</li>
              <li>
                One koji su gadljivi, pretjerano okupirani čistoćom, detaljima,
                ritualima
              </li>
              <li>
                One koji ne podnose rizik, traže garanciju i idu isključivo “na
                sigurno”
              </li>
              <li>One koji su nezadovoljni sobom i/ili svojim izgledom</li>
              <li>
                One koji su skloni da ponavljaju greške, nesposobni da izvuku
                pouku iz predhodnih loših iskustava
              </li>
              <li>Socijalno i/ili emotivno nezrele...</li>
              <li>
                Za one koji su izgubili samopouzdanje i one koji ga nikad nisu
                imali
              </li>
              <li>
                Za nestrpljive, razdražljive ljude, za one koji pretjeruju,
                uvijek su u žurbi i požuruju druge
              </li>
              <li>Strah od neuspjeha. Osjećanje inferiornosti</li>
              <li>
                Za iscrpljene ljude, za sticanje mentalne i fizičke kondicije
              </li>
              <li>Za ljude koje proganja osjećaj krivice, griža savjesti</li>
              <li>I one koji pretjerano brinu za porodicu i druge ljude</li>
              <li>
                Za uvijek negativne i nesigurne; za one sa ekstremno
                promjenljivim ponašanjem
              </li>
              <li>Ljudi koji pate od upornih, proganjajućih misli</li>
              <li>Za gubitak nade u promjenu na bolje</li>
              <li>Apatija. Rezignacija.</li>
              <li>
                Oboljenja zbog tuge... onda kada ne može da se nadvlada žalost.
              </li>
              <li>Ljubomora, zavist, mržnja, zloba, ogorčenost</li>
              <li>Putokaz za one koji su izgubili smisao života</li>
              <li>Bespomoćnost</li>
              <li>Sklonost perfekciji</li>
              <li>
                Usamljenost, depresija, strepnja (anksioznost), strah, očaj,
                kajanje
              </li>
              <li>Gubitak samokontrole. Nekontrolisani bijes</li>
              <li>...i još mnogo toga...</li>
            </ul>
          </section>
          <section id="box4">
            <header className="box">
              <h3>Kako do Bahovih Kapi</h3>
            </header>
            <Img
              fluid={data.sectionImage4_sm.childImageSharp.fluid}
              style={{ maxHeight: "70vh", opacity: "0.75" }}
            />
            <p>
              Mnogi ljudi se, ako ne svakodnevno, a onda s vremena na vrijeme
              susreću sa različitim problemima – briga, nervoza, neizvjesnost...
            </p>
            <p>
              Naime, neispunjeni zahtjevi i očekivanja su razlog za pojavu
              negativnih osjećanja. Kada se stvari i događaju odvijaju prema
              očekivanju, onda slijede pozitivna osjećanja: zadovoljstvo, sreća,
              ushićenost, ponos...{" "}
            </p>
            <p>
              Kada, pak, ne ispadne onako kako je očekivano, čovjek se osjeća
              osujećeno, a iz toga kao negativne emocije tu su sada: tuga,
              bijes, ljubomora, uvrijeđenost, osjećanje manje vrijednosti,
              strah, panika, gubitak vjere, sumnja, zavist, itd.
            </p>
            <p>
              Niko ne mora ovakvo stanje da trpi, jer je sve ovo{" "}
              <strong> moguće eliminisati Bahovim kapima</strong>.
            </p>
            <p>
              Svako ko je zainteresovan za upotrebu ovih kapi i eliminisanje
              negativnih stanja može da se obrate Bahovom praktičaru. Praktičar
              je edukovan da pomogne osobi da se usredsrijedi na svoje emotivno
              stanje, da jasno izrazi svoje misli i osjećanja, da ako je moguće
              pravim imenom nazove svoje stanje i reakcije, jer nije svima lako
              da se analiziraju, niti su spremni da se suoče sa samim sobom.
            </p>
            <p>
              U opuštenoj atmosferi i uz pomoć pitanja, moguće je osvijestiti
              dominantna negativna osjećanja ili negativna mentalna stanja.
              Pošto je praktičar tu i da sluša, i to može pomoći osobi da se
              lakše usredsrijedi na svoje emotivno stanje i da tako lakše i
              jasnije izrazi svoje misli i osjećanje.
            </p>
            <p>
              Po završenom razgovoru – intervjuu, dobija se kombinacija
              preparata koja će pokriti najvidljivije i najizraženije probleme –
              zbog toga što se u tretmanu Bahovim kapima ide po principu
              skidanje sloj po sloj. Zato se kreće od najvidljivijih i
              najpovršnijih, a po njihovom otklanjanju skidaju se sljedeći
              slojevi sve dok se ne dopre do onog najskrivenijeg i najdubljeg
              sloja ličnosti koji se onda tretira.
            </p>
            <p>
              Bahov praktičar će također objasniti upotrebu kapi i šta klijent
              može da očekuje. Prva poboljšanja koja se uočavaju biće
              individualna i zavise od toga koliko je individua dugo nosila u
              sebi neprijatna i opterećujuća stanja. Kod akutnih problema, prvi
              efekti kapi su primjetni vrlo brzo – za nekoliko sati.
            </p>
            <p>
              U svrhu što profesionalnijeg pružanja usluga klijentima i daljoj
              edukaciji praktičara, osnovano je{" "}
              <strong>
                {" "}
                ''Udruženje za promociju i metode dr. Edvarda Baha'' – ''Bahove
                cvijetne esencije''
              </strong>
              . Udruženje ima kodeks ponašanja koji svi Bahovi praktičari moraju
              da poštuju u svom radu sa klijentima.
            </p>
          </section>
          <section id="box5" className="section">
            <header className="box">
              <h3>Zašto je bitno obaviti intervju sa bahovim praktičarem</h3>
            </header>
            <Img
              fluid={data.sectionImage5_sm.childImageSharp.fluid}
              style={{ maxHeight: "70vh", opacity: "0.85" }}
            />
            <p>
              Suštinski zadatak Bahovog praktičara je da pomogne klijentu da
              osvijesti svoje mentalno ili emotivno stanje.
            </p>
            <p>
              Zašto je bitno da klijent bude toga svjestan? Zato što uz Bahove
              kapi rastemo i razvijamo se kao ličnosti – a to ne može da se
              odvija nasumično, stihijski, bez svijesti od tome koji su naši
              potencijali i na koji način treba da ih razvijemo u kapacitete
              (vrline, sposobnosti, vještine, znanja ,...). Takođe je bitno da
              smo svjesni koje su se prepreke unutar nas ispriječile našem
              unutrašnjem spokoju, ili ispriječile našoj komunikaciji sa samim
              sobom ili sa nekim iz našeg okruženja. Da li je ta prepreka naša
              netolerancija, posesivnost, strah, nestrpljivost, socijalna ili
              emotivna nezrelost, nedostatak samopouzdanja, samokontrole,
              nedostatak volje ili optimizma...
            </p>
            <p>
              Dva su preduslova za življenje života punim plućima: jedan je
              poznavanje i prihvatanje sebe (sa svim vrlinama i slabim tačkama),
              a drugi je visoka tolerancija na frustraciju, na osujećenost naših
              očekivanja. Zašto je ispalo bitno da poznajemo i prihvatimo sami
              sebe? Zato što je to jedini način da prepoznamo i definišemo i
              svoje slabe tačke, svoje mane, svoje nesposobnosti, svoje
              nevještine, odnosno ono što nam se ne dopada kod sebe ili što nas
              odvede s vremena na vrijeme u neki ćorsokak, u neku patnju. Tek
              tada, poznajući sebe, mi smo u mogućnosti da mijenjamo ono što nam
              se ne dopada, da se usavršavamo tokom života, postajući tako u
              globalu zadovoljni sobom i svojim životom.
            </p>
            <p>
              Oba ova preduslova su fokus intervjua sa Bahovim praktičarem.
              Lakše je to uraditi sa nekim ko je stručan, ko će postaviti prava
              pitanja i pomoći vam, a ne nametnuti, da sami dođete do bitnih
              odgovora.
            </p>
            <h4>Iz ugla praktičara</h4>
            <p>
              Klijentkinja – buduća mlada mama, čula je za Bahove kapi i dolazi
              zbog problema, straha pred porod. Poslije obavljenog intervjua,
              praktičar joj je predložila Rescue Remedy, zasigurno najpoznatiji
              Bahov preparat. Pošto u njegov sastav ulazi 5 od 38 pojedinačnih
              cvjetova, Rescue je namijenjen za urgentne situacije i upravo je
              preparat koji je namijenjen i za ovu konkretnu situaciju.
              Klijentkinja je dobila upute na koji način će koristiti Rescue
              Remedy, pošto se on može koristiti na razne načine u zavisnosti od
              potrebe i situacije. Prije odlaska u bolnicu, uzimala je 4x4 kapi
              ispod jezika. Zamolila sam je da na dan pred operaciju ne pije
              kapi uobičajeno 4x4 kapi, već da ih uzima tako što će utrljavati
              kapi na pulsne tačke.
            </p>
            <p>
              Porod je bio uspješan, a njen oporavak još uspješniji. Slijedila
              je moja uputstva tako da kada se probudila iz narkoze, posljedice
              operacije i anestezije su bile drastično manje nego
              pacijentkinjama iz sobe. Nije imala vrtoglavicu i mučninu, a
              stabilnost joj se relativno brzo vraćala. Sve je jako dobro
              podnijela i kapi su joj zaista pomogle da omogući svom tijelu da
              bez odlaganja započne sopstveni proces iscjeljenja.
            </p>
          </section>
        </article>
      </Layout>
    </>
  )
}

export default Upotreba

export const query = graphql`
  query {
    mobileImage: file(name: { eq: "upotreba-sm" }, extension: { eq: "png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    desktopImage: file(
      name: { eq: "upotreba-lg-n" }
      extension: { eq: "png" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    sectionImage1_sm: file(
      name: { eq: "cvijetno_zadovoljstvo" }
      extension: { eq: "jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sectionImage1: file(name: { eq: "cvijetno-z" }, extension: { eq: "jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sectionImage2_sm: file(
      name: { eq: "upotreba-min" }
      extension: { eq: "jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sectionImage2: file(name: { eq: "upotreba" }, extension: { eq: "jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sectionImage3_sm: file(
      name: { eq: "komekakopomažu-min" }
      extension: { eq: "jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sectionImage3: file(
      name: { eq: "kamomil-field" }
      extension: { eq: "jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sectionImage4_sm: file(
      name: { eq: "kakodoBahovihkapi-min" }
      extension: { eq: "jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sectionImage4: file(
      name: { eq: "upotreba-kapi" }
      extension: { eq: "jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sectionImage5_sm: file(
      name: { eq: "bitnoobavitiintervju-min" }
      extension: { eq: "jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sectionImage5: file(name: { eq: "kamomil" }, extension: { eq: "jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
